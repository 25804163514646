import React from 'react';
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import TestimonialSlide from './TestimonialSlide';
import { EffectCoverflow, Pagination } from "swiper";



import logo from '../images/logo.png';
import logoSmall from '../images/logo_small.png';
import logoMid from '../images/logo_mid.png';
import bg from '../images/main_bg.jpg';
import down from '../images/down2.png';
import scissors from '../images/scissors_black.png';
import razor from '../images/razor_black.png';
import scissorsandrazor from '../images/scissorsandrazor_black.png';

import gal1 from '../images/gal1.jpg';
import gal2 from '../images/gal2.jpg';
import gal3 from '../images/gal3.jpg';
import gal4 from '../images/gal4.jpg';
import gal5 from '../images/gal5.jpg';
import gal6 from '../images/gal6.jpg';
import arrow from '../images/arrow.png';
import rarrow from '../images/rarrow.png';
import img_now from '../images/img_now.jpg';
import img_then from '../images/img_then.jpg';
import testamonial from '../images/testamonial.png';
import location from '../images/location.png';
import reserve from '../images/reserve.png';

import Header from './Header';
import Footer from './Footer';
import { animateScroll as scroll } from 'react-scroll'
import { Link as Link1 } from 'react-scroll';
import { Link } from 'react-router-dom';
import '../style/home.css';
import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
    {
      "name": "Brijanje, oblikovanje i održavanje brade",
      "price": "10 KM",
      "description": "Usluga brijanja, oblikovanja i održavanja brade koja osigurava uredan i oštar izgled."
    },
    {
      "name": "Fazoniranje kose",
      "price": "10 KM",
      "description": "Profesionalne usluge stiliziranja kose za postizanje željenog izgleda."
    },
    {
        "name": "Brijanje glave",
        "price": "13 KM",
          "description": "Usluga kompletnog brijanja glave koja pruža uredan i besprijekoran izgled."
      },
    {
      "name": "Šišanje kratke kose",
      "price": "13 KM",
      "description": "Precizno šišanje kratkih frizura koje prati najnovije trendove i prilagođava se obliku lica."
    },
    {
      "name": "Šišanje srednje dužine kose",
      "price": "15 KM",
      "description": "Usluga šišanja za srednje duge frizure, prilagođena vašim željama i stilu."
    },
    {
      "name": "Šišanje duge kose",
      "price": "20 KM",
      "description": "Specijalizirana usluga šišanja za duge frizure koja osigurava zdrav izgled i sjaj kose."
    },
    {
      "name": "Šišanje i pranje",
      "price": "20 KM",
      "description": "Kombinirana usluga šišanja i pranja kose koja uključuje temeljito čišćenje i osvježavanje vaše frizure."
    }
  ]
  
  

const testimonials = [
    {
      imageSrc: testamonial,
      testimonialText: 'Vrhunska usluga. Mirza je nesvakidašnji frizer. Što zamislite i zaželite to vam još bolje i maštovitije napravi. Sve pohvale!!!',
      author: 'Bakir Hadžić',
      authorTitle: 'Stalna mušterija',
    },
    {
      imageSrc: testamonial,
      testimonialText: 'Great, friendly service. That place was recommended to me and now that I\'ve been there, I definitely recommend it, too.',
      author: 'Levent Saran',
      authorTitle: 'Stalna mušterija',
    },
    {
      imageSrc: testamonial,
      testimonialText: 'Odličan frizer, još bolji čovjek.',
      author: 'Haris H.',
      authorTitle: 'Stalna mušterija',
    },
    {
      imageSrc: testamonial,
      testimonialText: 'Najbolje mjesto u gradu.',
      author: 'Mirza Polić',
      authorTitle: 'Stalna mušterija',
    },
    {
      imageSrc: testamonial,
      testimonialText: '27 godina od kada dolazim. Sve je za pet 😊',
      author: 'Mirza Bajraktarević',
      authorTitle: 'Stalna mušterija',
    },
  ];

export default function Home() {
    return <>
        <Header />
        <div className="landing_page" onLoad={() => {
            document.body.style.overflowY = "scroll";
        }}>

            <div className='flex justify-center h-14 items-end'>
                <Link to={'/'} style={
                    {
                        zIndex: '1',
                    }
                }>
                    <img src={logo} alt="logo" className='h-10' />
                </Link>

            </div>
            <div className="menu " 
            style={
                {
                    zIndex: '1',
                }
            }>
                <i className="fas fa-bars" onClick={
                    () => {
                        document.querySelector('.menu_overlay').style.transform = 'translateY(0)';
                        document.body.style.overflowY = "hidden";
                    }
                }></i>
            </div>

            {/* page content */}
            <div className="main_bg relative"
                style={
                    {
                        marginTop: '-56px',
                        backgroundImage: `url(${bg})`,
                        height: '100vh',
                        width: '100%',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',

                    }
                }>
                <div className='h-full flex justify-center md:justify-center mx-auto items-center w-5/6 md:w-2/3 flex-col md:flex-row '>
                    <div className='w-full md:w-2/3'>
                        <h1 className='xl:text-7xl text-5xl font-bold text-wwhite mb-10 md:mb-8 text-center md:text-left'>Dobrodošli!</h1>
                        <p className='xl:text-2xl text-xl text-wwhite text-justify md:text-left'>
                            Mirza Vas očekuje u Sarajevu, naselje Hrasno, u frizerskom salonu “Seviljski berberin” namijenjenom muškarcima koji brinu o svom izgledu. Samo za vas od <span className='text-orange-400'>1991</span> godine.</p>
                    </div>

                        <button className='bg-orange-400 hover:bg-orange-500 text-wwhite font-bold xl:text-2xl text-xl py-3 px-6 rounded-2xl md:mt-0 mt-10 md:ml-10 ml-0'
                        onClick={
                            () => {
                                window.open('https://calendly.com/bbrrick/rezervacija')
                            }
                        }
                        >Rezerviši</button>
                </div>
                <Link1                       
                smooth={true} duration={1000}
                      to={"services"}
                      // a bit of a hack to make the link not go fully to services but to the top of the div
                        offset={-80}
                      >
                <div className='flex justify-center items-center w-full absolute bottom-5 animate-bounce cursor-pointer'>
                    <div className='flex flex-col'>
                        <span className='w-12 text-center text-xl text-wwhite'>Klizni</span>
                        <img src={down} alt="down" className=' w-12 h-12 ' />
                    </div>

                </div>
                </Link1>
            </div>

            {/* <div className='flex container mx-auto justify-evenly items-center w-full xl:flex-row flex-col' id='services'
                style={
                    {
                        minHeight: '60vh',
                    }
                }>
                <div className='desc_box'>
                    <div className='w-full flex justify-center'>
                        <div className='db_bg'>
                            <img src={scissors} alt="scissors" className='db_img h-14' />
                        </div>

                    </div>
                    <h5 className='text-4xl text-wwhite mb-10 mt-16 text-center'>Sisanje</h5>
                    <p className='text-xl text-wwhite text-justify'>
                        Svako šišanje je individulano prilagođeno potrebama i željama klijenta sa predpranjem, šišanjem, postpranjem i modeliranjem frizure. Rezervišite ovo naše povoljno i ugono iskustvo klikom na ovoj stranici. Tretman oko 30 minuta.
                    </p>

                </div>
                <div className='desc_box'>
                    <div className='w-full flex justify-center'>
                        <div className='db_bg'>

                            <img src={razor} alt="razor" className='db_img h-14' />
                        </div>
                    </div>
                    <h5 className='text-4xl text-wwhite mb-10 mt-16 text-center'>Brijanje</h5>
                    <p className='text-xl text-wwhite text-justify'>
                        Sve želje klijenta bit će zadovoljena, bez obzira da li se radi o potpunom brijanju, skraćivanju ili fazoniranju brade uz korištenje kvalitetnih proizvoda. Nudimo povoljno iskustvo i sve što treba vašoj bradi ili brkovima. Tretman oko 30 minuta.
                    </p>
                </div>
                <div className='desc_box'>
                    <div className='w-full flex justify-center'>
                        <div className='db_bg '>

                            <img src={scissorsandrazor} alt="scissorsandrazor" className='db_img h-12' />
                        </div>
                    </div>
                    <h5 className='text-4xl text-wwhite mb-10 mt-16 text-center'>Full Cut</h5>
                    <p className='text-xl text-wwhite text-justify'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laudantium rerum commodi blanditiis quae enim perferendis et vel temporibus labore nesciunt doloremque, porro asperiores beatae illum incidunt sunt accusamus animi? Sed.</p>

                </div>

            </div>
 */}

<div className="mx-auto max-w-7xl px-6 lg:px-8 my-20" id='services'>
        <div className='max-w-2xl lg:mx-0 lg:max-w-none mx-auto mt-20 mb-20'>
        <p className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">Tradicija</p>
            <div className='w-full flex lg:flex-row flex-col justify-center space-x-0 space-y-10 lg:space-y-0 lg:space-x-10 mt-10'>
                <div className='then flex flex-col items-center flex-1 w-full'>
                    <div className='overlay relative w-full h-full'>
                        <img src={img_then} alt="img then" className='rounded-xl w-full'/>
                        <div className='overlay_content absolute top-0 left-0 mx-3 my-2 bg-black bg-opacity-70 py-2 px-3 rounded-xl'>
                            <h3 className='text-lg text-white'>Sergej i Mirza 2005</h3>
                        </div>
                    </div>
                </div>
                <div className='now flex flex-col items-center flex-1 w-full'>
                    <div className='overlay relative w-full h-full'>
                        <img src={img_now} alt="img now" className='rounded-xl w-full'/>
                        <div className='overlay_content absolute top-0 left-0 mx-3 my-2 bg-black bg-opacity-70 py-2 px-3 rounded-xl'>
                            <h3 className='text-lg text-white'>Sergej i Mirza 2021</h3>
                        </div>
                    </div>
                </div>
            </div>
            <p className='text-gray-200 mt-10 text-lg font-bold italic text-justify px-10 mx-auto'>Neke mušterije prvi put dođu kod nas na šišanje u radnju za vrijeme svoje vrtičke ili osnovnoškolske dobi, ali ostanu vjerni i kad odrastu, te potpuno promijene modni stil. Danas imamo mnogo stalnih mušterija koji nas često posjete.</p>
        </div>
        </div>

<div className="mx-auto max-w-7xl px-6 lg:px-8 my-20">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-base font-semibold leading-7 text-orange-500">Sve što vam treba</h2>
            <p className="mt-2 text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">Cjenovnik</p>
            <p className="mt-6 text-base leading-7 text-gray-400">
            Nudimo širok spektar usluga prilagođenih vašim potrebama. U nastavku možete pronaći detaljan cjenovnik naših usluga:
            </p>
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-16">
                <dt className="font-semibold text-gray-100">
                  <span className="absolute left-0 top-0 h-5 text-orange-400 font-bold" aria-hidden="true">{feature.price}</span>
                  {feature.name}
                </dt>
                <dd className="mt-2 text-gray-300">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>

      <div className="mx-auto max-w-7xl px-6 lg:px-8 my-20">
        <div className='max-w-2xl lg:mx-0 lg:max-w-none mx-auto mt-20 mb-20'>
        <p className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">Brijačnica</p>
        
        </div>
      <div className="mx-auto grid max-w-2xl lg:mx-0 lg:max-w-none grid-cols-2 md:grid-cols-3 gap-x-8 gap-y-16">
        <img src={gal1} alt="gal1" className='rounded-xl'/>
                <img src={gal2} alt="gal2" className='rounded-xl'/>
                <img src={gal3} alt="gal3" className='rounded-xl'/>
                <img src={gal4} alt="gal4" className='rounded-xl'/>
                <img src={gal5} alt="gal5" className='rounded-xl'/>
                <img src={gal6} alt="gal6" className='rounded-xl'/>
                </div>

      </div>
      <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-20">
        <div className='max-w-2xl lg:mx-0 lg:max-w-none mx-auto mt-20 mb-10'>
        <p className="text-3xl font-bold tracking-tight text-gray-200 sm:text-4xl">Drugi su rekli</p>
        
                <Swiper
                    effect={"coverflow"}
                    grabCursor={true}
                    centeredSlides={true}
                    slidesPerView={1}
                    loop={true}
                    breakpoints={{

                        1000: {
                          slidesPerView: 3,
                        },
                      }}
                    coverflowEffect={{
                        rotate: 50,
                        stretch: 0,
                        depth: 100,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    pagination={false}
                    modules={[EffectCoverflow, Pagination]}
                    className="mySwiper mt-10"
                    style={
                        {
                            paddingTop: '20px',
                        }
                    }
                >
                    {/* <SwiperSlide>

                        <div className="flex items-center justify-center px-5 py-5">
                            <div className="w-full mx-auto max-w-xl rounded-lg bg-white dark:bg-gray-800 px-5 pt-5 pb-10 text-gray-800 dark:text-gray-50">
                                <div className="w-full pt-1 text-center pb-5 -mt-16 mx-auto">
                                    <a href="#" className="block relative">
                                        <img alt="profil" src={testamonial} className="mx-auto object-cover rounded-full h-20 w-20 " />
                                    </a>
                                </div>
                                <div className="w-full mb-10">
                                    <div className="text-3xl text-orange-500 text-left leading-tight h-3">
                                        “
                                    </div>
                                    <p className="text-sm text-gray-600 dark:text-gray-100 text-center px-5">
                                    Vrhunska usluga. Mirza je nesvakidašnji frizer. Što zamislite i zaželite to vam još bolje i maštovitije napravi. Sve pohvale!!!
                                    </p>
                                    <div className="text-3xl text-orange-500 text-right leading-tight h-3 -mt-3">
                                        ”
                                    </div>
                                </div>
                                <div className="w-full">
                                    <p className="text-md text-orange-500 font-bold text-center">
                                        Bakir Hadžić 
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-300 text-center">
                                        Stalna mušterija
                                    </p>
                                </div>
                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>

                        <div className="flex items-center justify-center px-5 py-5">
                            <div className="w-full mx-auto max-w-xl rounded-lg bg-white dark:bg-gray-800 px-5 pt-5 pb-10 text-gray-800 dark:text-gray-50">
                                <div className="w-full pt-1 text-center pb-5 -mt-16 mx-auto">
                                    <a href="#" className="block relative">
                                        <img alt="profil" src={testamonial} className="mx-auto object-cover rounded-full h-20 w-20 " />
                                    </a>
                                </div>
                                <div className="w-full mb-10">
                                    <div className="text-3xl text-orange-500 text-left leading-tight h-3">
                                        “
                                    </div>
                                    <p className="text-sm text-gray-600 dark:text-gray-100 text-center px-5">
                                    Great, friendly service. That place was recommended to me and now that I've been there, I definitely recommend it, too.
                                    </p>
                                    <div className="text-3xl text-orange-500 text-right leading-tight h-3 -mt-3">
                                        ”
                                    </div>
                                </div>
                                <div className="w-full">
                                    <p className="text-md text-orange-500 font-bold text-center">
                                    Levent Saran                                     </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-300 text-center">
                                    Stalna mušterija
                                    </p>
                                </div>
                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>

                        <div className="flex items-center justify-center px-5 py-5">
                            <div className="w-full mx-auto max-w-xl rounded-lg bg-white dark:bg-gray-800 px-5 pt-5 pb-10 text-gray-800 dark:text-gray-50">
                                <div className="w-full pt-1 text-center pb-5 -mt-16 mx-auto">
                                    <a href="#" className="block relative">
                                        <img alt="profil" src={testamonial} className="mx-auto object-cover rounded-full h-20 w-20 " />
                                    </a>
                                </div>
                                <div className="w-full mb-10">
                                    <div className="text-3xl text-orange-500 text-left leading-tight h-3">
                                        “
                                    </div>
                                    <p className="text-sm text-gray-600 dark:text-gray-100 text-center px-5">
                                    Odličan frizer, još bolji čovjek.
                                    </p>
                                    <div className="text-3xl text-orange-500 text-right leading-tight h-3 -mt-3">
                                        ”
                                    </div>
                                </div>
                                <div className="w-full">
                                    <p className="text-md text-orange-500 font-bold text-center">
                                    Haris H.
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-300 text-center">
                                    Stalna mušterija

                                    </p>
                                </div>
                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>

                        <div className="flex items-center justify-center px-5 py-5">
                            <div className="w-full mx-auto max-w-xl rounded-lg bg-white dark:bg-gray-800 px-5 pt-5 pb-10 text-gray-800 dark:text-gray-50">
                                <div className="w-full pt-1 text-center pb-5 -mt-16 mx-auto">
                                    <a href="#" className="block relative">
                                        <img alt="profil" src={testamonial} className="mx-auto object-cover rounded-full h-20 w-20 " />
                                    </a>
                                </div>
                                <div className="w-full mb-10">
                                    <div className="text-3xl text-orange-500 text-left leading-tight h-3">
                                        “
                                    </div>
                                    <p className="text-sm text-gray-600 dark:text-gray-100 text-center px-5">
                                    Najbolje mjesto u gradu.                                    </p>
                                    <div className="text-3xl text-orange-500 text-right leading-tight h-3 -mt-3">
                                        ”
                                    </div>
                                </div>
                                <div className="w-full">
                                    <p className="text-md text-orange-500 font-bold text-center">
                                    Mirza Polić
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-300 text-center">
                                    Stalna mušterija
                                    </p>
                                </div>
                            </div>
                        </div>

                    </SwiperSlide>
                    <SwiperSlide>

                        <div className="flex items-center justify-center px-5 py-5">
                            <div className="w-full mx-auto max-w-xl rounded-lg bg-white dark:bg-gray-800 px-5 pt-5 pb-10 text-gray-800 dark:text-gray-50">
                                <div className="w-full pt-1 text-center pb-5 -mt-16 mx-auto">
                                    <a href="#" className="block relative">
                                        <img alt="profil" src={testamonial} className="mx-auto object-cover rounded-full h-20 w-20 " />
                                    </a>
                                </div>
                                <div className="w-full mb-10">
                                    <div className="text-3xl text-orange-500 text-left leading-tight h-3">
                                        “
                                    </div>
                                    <p className="text-sm text-gray-600 dark:text-gray-100 text-center px-5">
                                    27 godina od kada dolazim. Sve je za pet 😊 
                                    </p>
                                    <div className="text-3xl text-orange-500 text-right leading-tight h-3 -mt-3">
                                        ”
                                    </div>
                                </div>
                                <div className="w-full">
                                    <p className="text-md text-orange-500 font-bold text-center">
                                    Mirza Bajraktarević
                                    </p>
                                    <p className="text-xs text-gray-500 dark:text-gray-300 text-center">
                                    Stalna mušterija
                                    </p>
                                </div>
                            </div>
                        </div>

                    </SwiperSlide> */}
                        {testimonials.map((testimonial, index) => (
      <SwiperSlide key={index}>
        <TestimonialSlide
          imageSrc={testimonial.imageSrc}
          testimonialText={testimonial.testimonialText}
          author={testimonial.author}
          authorTitle={testimonial.authorTitle}
        />
      </SwiperSlide>
    ))}
                   

                </Swiper>
                </div>
                </div>
                <div className='flex justify-center items-center text-wwhite text-lg mt-0'>
                    <img src={arrow} alt="left arrow" srcSet="" className='arrow'/>
                    <span className='mx-3'>Klizni</span>
                    <img src={rarrow} alt="right arrow" srcSet=""  className='rarrow'/>
                </div>

                {/* <h1 className='xl:text-5xl text-3xl font-bold text-wwhite mb-20 mt-32 md:mb-15 text-center'>Tradicija</h1>
                <div className='flex container mx-auto flex-col lg:flex-row justify-evenly items-center text-wwhite text-lg mt-5'>
                    <div className='flex flex-col mx-3 text-center lg:mb-0 mb-10' 
                    style={
                        {
                            width: '350px',
                        }
                    }
                    >
                     <img src={img_then} alt="img then" className='rounded-xl'

                     />
                        <h3 className='font-bold text-2xl mt-10 mb-3'>
                            Počeci
                        </h3>
                     <p className='text-justify w-5/6 mx-auto text-sm'>
                        Neke mušterije prvi put dođu kod nas na šišanje u radnju za vrijeme svoje vrtičke ili osnovnoškolske dobi... 
                        </p>
                     
                     <span className='mt-3 text-orange-400'>Zima 2005.</span>
                    </div>
                    <div className='flex flex-col mx-3 text-center' 
                    style={
                        {
                            width: '350px',
                        }
                    }
                    >
                     <img src={img_now} alt="img now" className='rounded-xl'


                     />
                        <h3 className='font-bold text-2xl mt-10 mb-3'>
                            Danas
                        </h3>
                     <p className='text-justify w-5/6 mx-auto text-sm'>
                     ...ali ostanu vjerni i kad odrastu, te potpuno promijene modni stil. Danas imamo mnogo stalnih mušterija koji nas često posjete.
                        </p>
                     <span className='mt-3 text-orange-400'>Ljeto 2021.</span>
                    </div>
                </div> */}

        <div className="w-full mt-20 flex justify-center items-center md:space-y-0 space-y-10 md:flex-row flex-col">
                <div className='w-full flex justify-center items-center py-10' onClick={
                    () => {
                        window.open('https://calendly.com/bbrrick/rezervacija')
                    }
                }
                style={
                    {
                        backgroundImage: `url(${reserve})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        // make darkened the image not the div
                        
                    }
                }
                >
                    <button className='bg-orange-400 hover:bg-orange-500 text-white font-bold xl:text-2xl text-xl py-3 px-6 rounded-2xl mt-10 mb-10'
                    >
                        Rezerviši
                    </button>
                </div>
                <div className='w-full flex justify-center items-center py-10' onClick={
                    () => {
                        window.open('https://maps.app.goo.gl/myPkuGSan44R44Yu8')

                    }
                }
                style={
                    {
                        backgroundImage: `url(${location})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    }
                }
                >
                    <button className='bg-orange-400 hover:bg-orange-500 text-white font-bold xl:text-2xl text-xl py-3 px-6 rounded-2xl mt-10 mb-10'

                    >
                        Lokacija
                    </button>
                </div>
        </div>
                

                {/* <div className='mx-auto container mt-32 text-center text-wwhite'>
                    <h1 className='xl:text-5xl text-3xl mb-20 font-bold'>Čekamo vas</h1>
                    <div className='flex justify-evenly items-center flex-col md:flex-row'>
                        <div className='flex flex-col'>
                            <h3 className='text-3xl mb-5'>Rezerviši</h3>
                            <Link to={'/rezervisi'}>
                            <button className='mb-4 mx-auto bg-orange-400 hover:bg-orange-500 text-wwhite font-bold xl:text-2xl text-xl py-3 px-6 rounded-2xl w-32'>Ovdje</button>
                            </Link>
                            <p className="" 
                            style={
                                {
                                    maxWidth: '400px',
                                }
                            }>Odaberte dan, željeni termin i uslugu koja vas interesuje. Za to sve trebat će vam manje od dvije minute</p>

                        </div>
                        <div className='md:h-32 my-10 md:my-0 md:border-r-2 mx-5 border-gray-500 border-b-2 md:w-0 w-32'>

                        </div>
                        <div className='flex flex-col'>
                            <h3 className='text-3xl mb-5'>Lokacija</h3>
                            <button className='mb-4 mx-auto bg-orange-400 hover:bg-orange-500 text-wwhite font-bold xl:text-2xl text-xl py-3 px-6 rounded-2xl w-32' onClick={
                                () => {
                                    window.open('https://www.google.com/maps/place/Frizerski+Salon+Seviljski+Berber/@43.8472075,18.3752515,15z/data=!4m5!3m4!1s0x0:0x4f26df4bb51ce91c!8m2!3d43.8472153!4d18.3752486')
                                }
                            }>Ovdje</button>
                            <p className=""
                            style={
                                {
                                    maxWidth: '400px',
                                }
                            }>Potražite nas na google maps. Lokacija se nalazi u Hrasnom u Sarajevu, na adresi Milana Preloga 6.</p>

                        </div>
                    </div>
                </div> */}



            <Footer />
        </div>

    </>
}
