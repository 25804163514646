import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
// import Reservations from "./pages/Reservations";
import About from "./pages/About";
import Admin from "./pages/Admin";
import Stalni from "./pages/Stalni";
import Blocked from "./pages/Blocked"
import './style/app.css';
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/rezervisi" element={<Reservations />} /> */}
        <Route path="/o-nama" element={<About />} />
        <Route path="/admin" element={<Admin />} />
        <Route path="/stalni_klijenti" element={<Stalni />} />
        <Route path="/blokirani_dani" element={<Blocked />} />
      </Routes>
    </BrowserRouter>
  );
}