import React from 'react';
import PropTypes from 'prop-types';

const TestimonialSlide = ({ imageSrc, testimonialText, author, authorTitle }) => (
  <div className="flex items-center justify-center px-5 py-5">
    <div className="w-full mx-auto max-w-xl rounded-lg bg-white dark:bg-gray-800 px-5 pt-5 pb-10 text-gray-800 dark:text-gray-50">
      <div className="w-full pt-1 text-center pb-5 -mt-16 mx-auto">
        <a href="#" className="block relative">
          <img alt="profil" src={imageSrc} className="mx-auto object-cover rounded-full h-20 w-20" />
        </a>
      </div>
      <div className="w-full mb-10">
        <div className="text-3xl text-orange-500 text-left leading-tight h-3">
          “
        </div>
        <p className="text-sm text-gray-600 dark:text-gray-100 text-center px-5">
          {testimonialText}
        </p>
        <div className="text-3xl text-orange-500 text-right leading-tight h-3 -mt-3">
          ”
        </div>
      </div>
      <div className="w-full">
        <p className="text-md text-orange-500 font-bold text-center">
          {author}
        </p>
        <p className="text-xs text-gray-500 dark:text-gray-300 text-center">
          {authorTitle}
        </p>
      </div>
    </div>
  </div>
);

TestimonialSlide.propTypes = {
  imageSrc: PropTypes.string.isRequired,
  testimonialText: PropTypes.string.isRequired,
  author: PropTypes.string.isRequired,
  authorTitle: PropTypes.string.isRequired,
};

export default TestimonialSlide;