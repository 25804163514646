import React from 'react'
import { useState, useEffect } from 'react'
import logo from '../images/logo.png'
import remove from '../images/close.png'
import add from '../images/plus.png'
import { initializeApp } from "firebase/app";
import { doc, getDocs, collection, getFirestore, deleteDoc, setDoc} from "firebase/firestore";
import { Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// Initialize Firebase
import { firebaseConfig } from "../firebase";
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore()

async function removeClient(name){
    await deleteDoc(doc(db, "Clients", name));
    document.location.href = '/stalni_klijenti'
}

async function addClient(name, nick){
    await setDoc(doc(db, "Clients", name), {
        nickname: nick,
      });
    document.location.href = '/stalni_klijenti'
}

export default function Stalni() {

    const [loaded, setLoaded] = useState(false);
    const [logged, setLog] = useState(false);
    const [clients, setClients] = useState([]);
    useEffect(() => {
        let clients_local = []
        getDocs(collection(db, "Clients")).then(querySnapshot => {
            querySnapshot.forEach((doc) => {
                clients_local.push([doc.id, doc.data().nickname])
            })
        })
        setClients(clients_local)
        setLoaded(true)
    }, []);


    // ! for debug
    if (logged) {
        if (loaded) {
            return <div className="flex flex-col">
                <div className='flex justify-center flex-col items-center items-end mb-20 mt-10'>
                    <img src={logo} alt="logo" className='h-10' />
                    <p className="text-2xl text-white mt-3">Stalni klijenti</p>
                </div>
                <p className="text-white font-bold text-xl md:w-3/4 mx-auto mt-10 mb-4">DODAJ KLIJENTA:</p>
                <div className="flex items-center justify-center md:w-3/4 w-full md:flex-row flex-col mx-auto text-xl">
                    <input className="shadow appearance-none border md:rounded-l-xl w-full py-2 px-3 h-16 text-grey-darker" id="name" type="text" placeholder="Ime i prezime" />
                    <input className="shadow appearance-none border border-red md:rounded-r-xl w-full py-2 h-16 px-3 text-grey-darker" id="nick" type="text" placeholder="Nadimak" />
                    <div className="h-16 md:ml-5 hover:bg-green-600 bg-green-400 w-full md:w-44 flex items-center md:rounded-xl justify-center"
                    onClick={() => addClient(document.getElementById("name").value, document.getElementById("nick").value)}>
                        <img src={add} alt="add" className="h-6 w-6"/>
                    </div>
                </div>

                <p className="text-white font-bold text-xl md:w-3/4 mx-auto mt-10 mb-4">SPASENI KLIJENTI:</p>
                {clients.map(client => 
                <div className="flex flex-col md:flex-row w-full md:w-3/4 items-center mx-auto justify-evenly capitalize text-black text-xl mb-3">
                    <div className="h-16 bg-gray-400 py-3 px-5 w-full flex items-center font-bold justify-center md:rounded-l-xl">{client[0]}</div>
                    <div className="h-16 bg-orange-400 py-3 px-5 w-full flex items-center justify-center md:rounded-r-xl uppercase">{client[1]}</div>
                    <div className="h-16 md:ml-5 hover:bg-red-600 bg-red-400 w-full md:w-44 flex items-center md:rounded-xl justify-center"
                    onClick={() => removeClient(client[0])}>
                        <img src={remove} alt="remove" className="h-5 w-5"/>
                    </div>
                </div>

                )}
            </div>
        } else {
            return (
                <div className="text-white flex justify-center items-center text-xl w-screen h-screen">
                    <h1>Pricekajte...</h1>
                </div>
            )
        }
    } else {
        return (
            <div className="flex flex-col items-center justify-evenly h-screen">
                <img src={logo} alt="logo" className="h-20" />
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col container mx-auto">
                    <div className="mb-4">
                        <label className="block text-grey-darker text-sm font-bold mb-2" for="email">
                            Email
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="email" type="text" placeholder="Email" />
                    </div>
                    <div className="mb-6">
                        <label className="block text-grey-darker text-sm font-bold mb-2" for="password">
                            Password
                        </label>
                        <input className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" id="password" type="password" placeholder="******************" />
                    </div>
                    <div className="flex items-center justify-between">
                        <button className="bg-orange-300 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded" type="button" onClick={
                            () => {
                                let email = document.getElementById("email").value
                                let password = document.getElementById("password").value
                                signInWithEmailAndPassword(auth, email, password)
                                    .then((userCredential) => {
                                        const user = userCredential.user;
                                        setLog(true)
                                    })
                                    .catch((error) => {
                                        const errorCode = error.code;
                                        const errorMessage = error.message;
                                        alert(errorCode, errorMessage)
                                    });
                            }
                        } >
                            Sign In
                        </button>
                    </div>
                </div>
            </div>
        )

    }

}
