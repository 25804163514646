import React from 'react'
import { useState, useEffect } from 'react'
import logo from '../images/logo.png'
import { initializeApp } from "firebase/app";
import { doc, getDocs, collection, getFirestore } from "firebase/firestore";
import { Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// Initialize Firebase
import { firebaseConfig } from "../firebase";
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore()


export default function Admin() {

  const [loaded, setLoaded] = useState(false);

  const [logged, setLog] = useState(false);
  const [list, setL] = useState([])

  useEffect(() => {



    const a = []

    var dt = new Date();
    dt.setHours(0, 0, 0, 0)
    let now_m = (dt.getMonth() + 1)
    let now_d = dt.getDate();
    let now_y = dt.getFullYear();
    let clients_local = []
    getDocs(collection(db, "Clients")).then(querySnapshot => {
        querySnapshot.forEach((doc) => {
            clients_local.push([doc.id, doc.data().nickname])
        })
    })
    console.log(clients_local)
    getDocs(collection(db, "Dates")).then(querySnapshot => {
      let helparray = []
      querySnapshot.forEach((doc) => {
        let keys = doc.id
        let day = keys.split("-")[0]
        let month = keys.split("-")[1]
        let year = keys.split("-")[2]
        let t_date = new Date(year + "-" + month + "-" + day)
        if (t_date >= dt) {
          let values = doc.data()
          let blocked = values.Blocked
          let times = values.Times
          if (times !== undefined) {
          let timeshelp = []
          Object.entries(times).forEach(time => {
            // fix undefined bug in admin
            let c_name = time[1].name
            clients_local.forEach(client => {
              if (client[0].toUpperCase() === c_name.toUpperCase()){
                c_name = c_name + " (" + client[1] + ")"
              }
            })
            timeshelp.push([time[0], c_name, time[1].type, time[1].phone, parseInt(time[0].split(":")[0])+(time[0].split(":")[1] === "30" ? 0.5 : 0)])
            
          })
          
          console.log(timeshelp)
          timeshelp.sort((t1, t2) => t1[4] - t2[4])
          timeshelp.forEach(line => {
            line.pop()
          })
          helparray.push([keys, blocked, timeshelp])
        }
        }
      })
      helparray.forEach(line => {
        line.push(new Date(line[0].split("-")[2] + "-" + line[0].split("-")[1] + "-" + line[0].split("-")[0]))
      })
      helparray.sort((date1, date2) => date1[3] - date2[3]);
      helparray.forEach(line => {
        line.pop()
      })
      helparray.length = 60
      setL(helparray)
      setLoaded(true)
    })
  }, []);


  // ! for debug
  if (logged) {
    if (loaded) {
      return <div className="flex flex-col">
        <Link to={'/stalni_klijenti'}>
          <div className="absolute h-10 w-screen top-0 m-0 p-0 left-0 flex items-center justify-center hover:bg-orange-600 bg-orange-400 text-white">
            Stalni klijenti
          </div>
        </Link>
        <Link to={'/blokirani_dani'}>
          <div className="absolute h-10 w-screen top-10 m-0 p-0 left-0 flex items-center justify-center hover:bg-orange-600 bg-orange-400 text-white">
            Blokirani dani
          </div>
        </Link>
        <div className='flex justify-center flex-col items-center items-end mt-36 mb-10'>
          <img src={logo} alt="logo" className='h-10' />
          <p className="text-2xl text-white mt-3">Admin stranica</p>
        </div>
        {list.map(entry =>
          <div key={entry[0]} className="flex md:mx-10 mx-2 items-ceter justify-between bg-orange-400 my-5 md:rounded-xl md:flex-row flex-col">
            <div className="bg-gray-700 text-white md:w-52 md:rounded-l-xl font-bold flex flex-col items-center justify-center">
              <div className="p-3">
                {entry[0]}
              </div>
              <div className="pb-3">
                <span className="font-bold text-blue-500">Radni dan: </span>
                {!entry[1] ? "DA" : "NE"}
              </div>
            </div>
            <div className="flex-1 py-5 md:text-lg text-sm">
              <div className="flex items-center justify-between font-bold mb-5">
                <div className="text-center basis-1/4">Vrijeme</div>
                <div className="text-center basis-1/4">Klijent</div>
                <div className="text-center basis-1/4">Usluga</div>
                <div className="text-center basis-1/4">Telefon</div>
              </div>
              {entry[2].map(function (d) {
                return (<div className="flex items-center justify-between mb-2">
                  <div className="text-center basis-1/4 font-bold">
                    {d[0]}
                  </div>
                  <div className="text-center basis-1/4">
                    {d[1]}
                  </div>
                  <div className="text-center basis-1/4">
                    {d[2]}
                  </div>
                  <div className="text-center basis-1/4">
                    {d[3]}
                  </div>
                </div>
                )
              })}</div>
          </div>
        )}</div>
    } else {
      return (
        <div className="text-white flex justify-center items-center text-xl w-screen h-screen">
          <h1>Pricekajte...</h1>
        </div>
      )
    }
  } else {
    return (
      <div className="flex flex-col items-center justify-evenly h-screen">
        <img src={logo} alt="logo" className="h-20" />
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col container mx-auto">
          <div className="mb-4">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">
              Email
            </label>
            <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="email" type="text" placeholder="Email" />
          </div>
          <div className="mb-6">
            <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="password">
              Password
            </label>
            <input className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" id="password" type="password" placeholder="******************" />
          </div>
          <div className="flex items-center justify-between">
            <button className="bg-orange-300 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded" type="button" onClick={
              () => {
                let email = document.getElementById("email").value
                let password = document.getElementById("password").value
                signInWithEmailAndPassword(auth, email, password)
                  .then((userCredential) => {
                    const user = userCredential.user;
                    setLog(true)
                  })
                  .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    alert(errorCode, errorMessage)
                  });
              }
            } >
              Sign In
            </button>
          </div>
        </div>
      </div>
    )

  }

}
