import React from 'react'

export default function Footer() {
    return (
        <div className="w-full text-sm flex flex-row justify-between items-end mt-20 px-10 pb-10 pt-3 text-white container mx-auto">

            <div>
            &copy; 2024 Seviljski Berberin 

            </div>
            <div className="text-right md:text-left">
            <p>Milana Preloga 6</p>
            <p>Sarajevo, Bosnia and Herzegovina</p>
            <p>+387 61 229 144</p>

            </div>
        </div>
    )
}
