import React from 'react'
import { Link} from "react-router-dom";


export default function Header() {
    return (
        <div className="menu_overlay">
            <i className="fas fa-times" onClick={
                () => {
                    document.querySelector('.menu_overlay').style.transform = 'translateY(-110%)';
                    document.body.style.overflowY = "scroll";
                }
            }></i>
            <ul className="list">
                <li><a href="/">Početna</a></li>
                <li><a href="https://calendly.com/bbrrick/rezervacija"
                target="_blank" rel="noreferrer"
                >Rezerviši</a></li>
            </ul>
        </div>
    )
}
