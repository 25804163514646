import React from 'react'
import { useState, useEffect } from 'react'
import logo from '../images/logo.png'
import remove from '../images/close.png'
import add from '../images/plus.png'
import { initializeApp } from "firebase/app";
import { doc, getDocs, collection, getFirestore, deleteDoc, setDoc, updateDoc  } from "firebase/firestore";
import { Link } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// Initialize Firebase
import { firebaseConfig } from "../firebase";
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore()

async function removeDay(date){
    await updateDoc(doc(db, "Dates", date), {
        Blocked: false
      });
    document.location.href = '/blokirani_dani'
}

async function addDay(date){
    console.log(date)
    await setDoc(doc(db, "Dates", date), {
        Blocked: true
      });
    document.location.href = '/blokirani_dani'
}

async function updateDay(date){
    console.log(date)
    await updateDoc(doc(db, "Dates", date), {
        Blocked: true
      });
    document.location.href = '/blokirani_dani'
}

export default function Blocked() {

    const [loaded, setLoaded] = useState(false);
    const [logged, setLog] = useState(false);
    const [dates, setDates] = useState([]);
    useEffect(() => {
        let dates_local = []
        getDocs(collection(db, "Dates")).then(querySnapshot => {
            querySnapshot.forEach((doc) => {
                dates_local.push([doc.id, doc.data().Blocked])
            })
        })
        console.log(dates_local)
        setDates(dates_local)
        setLoaded(true)
    }, []);


    // ! for debug
    if (logged) {
        if (loaded) {
            return <div className="flex flex-col">
                <div className='flex justify-center flex-col items-center items-end mb-20 mt-10'>
                    <img src={logo} alt="logo" className='h-10' />
                    <p className="text-2xl text-white mt-3">Neradni dani</p>
                </div>
                <p className="text-white font-bold text-xl md:w-3/4 mx-auto mt-10 mb-4">OZNACI DAN:</p>
                <div className="flex items-center justify-center md:w-3/4 w-full md:flex-row flex-col mx-auto text-xl">
                    <input className="shadow appearance-none border md:rounded-l-xl w-full py-2 px-3 h-16 text-grey-darker" id="day" type="number" min="1" max="31" placeholder="Dan (1-31)" />
                    <input className="shadow appearance-none border w-full py-2 px-3 h-16 text-grey-darker" id="month" type="number" min="1" max="12" placeholder="Mjesec (1-12)" />
                    <input className="shadow appearance-none border md:rounded-r-xl w-full py-2 px-3 h-16 text-grey-darker" id="year" type="number" min="2022" max="2050" placeholder="Godina (2022-2050)" />
                    <div className="h-16 md:ml-5 hover:bg-green-600 bg-green-400 w-full md:w-44 flex items-center md:rounded-xl justify-center"
                    onClick={() => {
                                let day = document.getElementById("day").value + "-" + document.getElementById("month").value + "-" + document.getElementById("year").value
                                let update = false
                                dates.forEach(single => {
                                    if (single[0] == day) (
                                        update = true
                                    )
                                })
                                update ? updateDay(day) : addDay(day)
                                //
                                //
                            }
                        }>
                        <img src={add} alt="add" className="h-6 w-6"/>
                    </div>
                </div>

                <p className="text-white font-bold text-xl md:w-3/4 mx-auto mt-10 mb-4">NERADNI DANI:</p>
                {dates.map(date => (date[1] ?
                <div className="flex flex-col md:flex-row w-full md:w-3/4 items-center mx-auto justify-evenly capitalize text-black text-xl mb-3">
                    <div className="h-16 bg-gray-400 py-3 px-5 w-full flex items-center font-bold justify-center md:rounded-xl">{date[0]}</div>
                    <div className="h-16 md:ml-5 hover:bg-red-600 bg-red-400 w-full md:w-44 flex items-center md:rounded-xl justify-center"
                    onClick={() => removeDay(date[0])}>
                        <img src={remove} alt="remove" className="h-5 w-5"/>
                    </div>
                </div> : <></>)
                )}
            </div>
        } else {
            return (
                <div className="text-white flex justify-center items-center text-xl w-screen h-screen">
                    <h1>Pricekajte...</h1>
                </div>
            )
        }
    } else {
        return (
            <div className="flex flex-col items-center justify-evenly h-screen">
                <img src={logo} alt="logo" className="h-20" />
                <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col container mx-auto">
                    <div className="mb-4">
                        <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker" id="email" type="text" placeholder="Email" />
                    </div>
                    <div className="mb-6">
                        <label className="block text-grey-darker text-sm font-bold mb-2" htmlFor="password">
                            Password
                        </label>
                        <input className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3" id="password" type="password" placeholder="******************" />
                    </div>
                    <div className="flex items-center justify-between">
                        <button className="bg-orange-300 hover:bg-orange-400 text-white font-bold py-2 px-4 rounded" type="button" onClick={
                            () => {
                                let email = document.getElementById("email").value
                                let password = document.getElementById("password").value
                                signInWithEmailAndPassword(auth, email, password)
                                    .then((userCredential) => {
                                        const user = userCredential.user;
                                        setLog(true)
                                    })
                                    .catch((error) => {
                                        const errorCode = error.code;
                                        const errorMessage = error.message;
                                        alert(errorCode, errorMessage)
                                    });
                            }
                        } >
                            Sign In
                        </button>
                    </div>
                </div>
            </div>
        )

    }

}
